html, body, p {
  padding: 0;
  margin: 0;
}
html {
  color: #eee;
  background: url("../images/darknoise.png") repeat scroll 0% 0% rgb(34, 34, 34);
  height: 100%;
}
body {
  background-image: url('../images/DSC_1931-min.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
  min-height: 100%;
  text-align: center;
}

h1 {
  font-family: 'Cookie', cursive;
  font-size: 54px;
  font-weight: 500;
  margin-bottom: 8px;
  text-shadow: 2px 1px #172B4D;
}

h2 {
  font-family: 'Cookie', cursive;
  font-size: 40px;
  font-weight: 500;
  margin-top: 4px;
  text-shadow: -1px 0 #172B4D, 0 1px #172B4D, 1px 0 #172B4D, 0 -1px #172B4D;
}

a {
  background-color: rgba(233, 244, 232, 0.75);
  border-radius: 3px;
  color: #172B4D;
  font-weight: 600;
  padding: 6px 12px;
  text-decoration: none;

  &:hover {
    background-color: rgba(233, 244, 232,.85);
  }

  &:active {
    background-color: rgb(233, 244, 232);
  }
}

.content {
  padding: 16px 0;
}

.actions {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 24px auto;
  max-width: 300px;
  padding: 0 8px;

  a {
    display: flex;
    flex-direction: column;
    padding: 8px 4px;
  }
}

.strike {
  margin-bottom: 0;
  text-decoration: line-through;
}

@media only screen and (max-width: 450px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }
}
